/* eslint-disable */

import '@fontsource/noto-sans-kr/400.css';
import '@fontsource/noto-sans-kr/500.css';
import '@fontsource/noto-sans-kr/700.css';

import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Chatbot from "./components/Chatbot";
import _Chatbot from "./dev/Chatbot";
import "./App.css";
import lightLogo from "./images/logo3.png";
import darkLogo from "./images/logo3.png";


const ToggleSwitch = ({ checked, onChange }) => (
  <label className="switch">
    <input type="checkbox" checked={checked} onChange={onChange} />
    <span className="slider round"></span>
  </label>
);

function Footer({ darkMode }) {
  return (
    <footer className="footer">
      <div className="brand-container">
        <img src={darkMode ? darkLogo : lightLogo} alt="NeuralWave Logo" className="logo-image" />
        
      </div>
    </footer>
  );
}

function App() {
  const [darkMode, setDarkMode] = useState(false);
  
  const toggleTheme = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  return (
    <Router>
      <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <Container fluid className="header-container">
  <div className="header-left">
    <div className="brand-container">
      <img src={darkMode ? darkLogo : lightLogo} alt="NeuralWave Logo" className="logo-image" />
      
    </div>
  </div>
  <div className="header-center">
  <span 
    className="chatbot-title"
    style={{ 
      fontFamily: '"Noto Sans KR", sans-serif',
      fontWeight: 700
    }}
  >
    추모공원 음성 챗봇
  </span>
</div>
  <div className="header-right">
    <ToggleSwitch checked={darkMode} onChange={toggleTheme} />
  </div>
</Container>
<div className="container">
          <div className="content">
            <Routes>
              <Route path="/" element={<Chatbot />} />
              <Route path="/dev" element={<_Chatbot />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </div>
        <div className="footer">
          <span className="footer-text">NeuralWave</span>
        </div>
      </div>
    </Router>
  );
}

export default App;