/* eslint-disable */

// 필요한 기능을 가져와요
import { useRef, useEffect, useCallback } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// 녹음 기능을 만드는 함수를 정의해요
export function useRecording(sendMessage) {
  // 여러 가지를 저장할 변수를 만들어요

  const mediaRecorderRef = useRef(null); // 녹음을 위한 변수
  const audioChunksRef = useRef([]); // 녹음된 소리 조각들을 저장할 변수
  const transcriptRef = useRef(''); // 텍스트 변환 결과를 저장할 변수
  const chunkCountRef = useRef(0);

  // 음성 인식 기능을 설정해요
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({
    continuous: false, // 연속적인 녹음은 하지 않아요
    interimResults: false, // 중간 결과는 필요 없어요
    useLegacyResults: false, // 최신 기능을 사용해요
  });


  // 텍스트 변환 결과가 바뀔 때마다 실행돼요
  useEffect(() => {
    if (transcript !== transcriptRef.current) {
      // 변환된 텍스트를 업데이트해요
      transcriptRef.current = transcript;
    }
    
    if (!listening) {
      // 듣고 있지 않다면 텍스트를 초기화해요
      transcriptRef.current = '';
    }
  }, [transcript, listening]);

  // 마이크 녹음을 멈추는 함수예요
  const handleMicStop = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    SpeechRecognition.stopListening();
    resetTranscript();
  }, [resetTranscript]);

  // 마이크 녹음을 시작하는 함수예요
  const handleMicStart = useCallback(() => {
    // console.log("handleMicStart called"); // 녹음 시작 로그를 찍어요
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        // console.log("Media stream obtained"); // 오디오 스트림을 얻었어요
        mediaRecorderRef.current = new MediaRecorder(stream); // 미디어 녹음기를 만들어요
        
        mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
          // console.log("Data available event triggered"); // 데이터가 준비되었어요
          audioChunksRef.current.push(event.data); // 소리 데이터를 저장해요
        });
        
        mediaRecorderRef.current.addEventListener('stop', () => {
          // console.log("MediaRecorder stopped"); // 녹음이 멈췄어요
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' }); // 소리 데이터를 블롭으로 만들어요
          const reader = new FileReader(); // 파일을 읽는 도구를 만들어요
          
          reader.onloadend = () => {
            // console.log("FileReader load ended"); // 파일 읽기가 끝났어요
            const base64data = reader.result.split(',')[1]; // 데이터를 Base64 형식으로 변환해요
            const chunkSize = 28 * 1024; // 청크 크기를 28KB로 줄여요
            const chunks = [];
            for (let i = 0; i < base64data.length && chunks.length < 10; i += chunkSize) {
              chunks.push(base64data.slice(i, i + chunkSize));
            }
            
            // console.log(`Sending ${chunks.length} audio chunks`); // 조각의 개수를 로그에 찍어요
            chunks.forEach((chunk, index) => {
              sendMessage(JSON.stringify({
                action: 'stop_recording',
                chunk_index: index,
                total_chunks: chunks.length,
                audio_data: chunk
              })); // 각 조각을 메시지로 보내요
            });
          };
          reader.readAsDataURL(audioBlob); // 블롭 데이터를 읽기 시작해요
          
          audioChunksRef.current = []; // 소리 조각들을 초기화해요
          chunkCountRef.current = 0;
        });
        
        // console.log("Starting MediaRecorder"); // 녹음을 시작해요
        mediaRecorderRef.current.start(1000); // 1초마다 데이터 이벤트 발생
        // 10초 후에 자동으로 녹음 중지

      })
      .catch((error) => {
        // console.error('Error accessing microphone:', error);
      });
  }, [sendMessage, handleMicStop]);

  // 함수들을 반환해요
  return {
    handleMicStart, // 녹음 시작 함수
    handleMicStop, // 녹음 멈춤 함수
    browserSupportsSpeechRecognition // 브라우저가 음성 인식을 지원하는지 여부
  };
}