/* eslint-disable */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Chatbot.css';
import { useRecording } from './Recording';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import humanAvatar from '../images/human.png';
import machineAvatar from '../images/machine.png';



function _Chatbot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [micOn, setMicOn] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isAllAudioPlayed, setIsAllAudioPlayed] = useState(false);
  const [micPermission, setMicPermission] = useState('prompt');
  const [audioQueue, setAudioQueue] = useState([]);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [chunkQueue, setChunkQueue] = useState([]);
  const [isSendingChunk, setIsSendingChunk] = useState(false);
  const [isAudioContextStarted, setIsAudioContextStarted] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [isResponseComplete, setIsResponseComplete] = useState(true);
  const [isResponseStarted, setIsResponseStarted] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const [isTextStreamingComplete, setIsTextStreamingComplete] = useState(false);
  const [audioContext, setAudioContext] = useState(null);
  const [isResponseTimedOut, setIsResponseTimedOut] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [playedChunks, setPlayedChunks] = useState([]);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(true);

  // 2. ref 변수들을 선언합니다.
  const audioRef = useRef(new Audio());
  const socketRef = useRef(null);
  const responseTimeoutRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const messageRefs = useRef([]);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  // 3. 상수 값들을 선언합니다.
  const MAX_CHARS = 840;
  const maxRetries = 25;

  // 4. 유틸리티 함수들을 선언합니다.
  const getButtonText = useCallback((isResponding, isAudioPlaying) => {
    return (isResponding || isAudioPlaying) ? '' : '';
  }, []);

  const isButtonDisabled = useCallback((isWebSocketConnected) => {
    return !isWebSocketConnected;
  }, []);

  const isInputDisabled = useCallback((isResponseComplete, isAudioPlaying) => {
    return !isResponseComplete || isAudioPlaying;
  }, []);

  const isMicButtonDisabled = useCallback((isResponseComplete, isAudioPlaying) => {
    return !isResponseComplete || isAudioPlaying;
  }, []);



  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '40px';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 60)}px`;
      textarea.style.overflowY = textarea.scrollHeight > 80 ? 'auto' : 'hidden';
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const handleChange = (e) => {
    const newInput = e.target.value.slice(0, MAX_CHARS);
    setInput(newInput);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const newInput = (input + pastedText).slice(0, MAX_CHARS);
    setInput(newInput);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isWebSocketConnected) {
      handleSubmit(e);
      if (textareaRef.current) {
        textareaRef.current.style.height = '40px';
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };




  const sendMessageWithRetry = useCallback((message, retries = 25, onSuccess = () => { }) => {
    const send = () => {
      if (connectionStatus === 'connected' && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(message);

        setIsWaitingForResponse(true);
        clearTimeout(responseTimeoutRef.current);
        responseTimeoutRef.current = setTimeout(() => {
          if (isWaitingForResponse) {
            setIsWaitingForResponse(false);
            console.error('Response timeout. Please try again.');
          }
        }, 7000);

        onSuccess();
      } else if (retries > 0) {
        console.error('WebSocket is not open. Retrying...');
        setTimeout(() => send(), 1000);
        retries--;
      } else {
        console.error('Failed to send message after retries');
        setConnectionStatus('failed');
      }
    };
    send();
  }, [connectionStatus, isWaitingForResponse]);

  const sendMessage = useCallback((message) => {
    sendMessageWithRetry(JSON.stringify(message));
  }, [sendMessageWithRetry]);

  const sendChunk = useCallback(() => {
    if (chunkQueue.length > 0 && !isSendingChunk) {
      setIsSendingChunk(true);
      const chunk = chunkQueue[0];
      sendMessageWithRetry(JSON.stringify(chunk), 3, () => {
        setChunkQueue(prevQueue => prevQueue.slice(1));
        setIsSendingChunk(false);
      });
    }
  }, [chunkQueue, isSendingChunk, sendMessageWithRetry]);

  useEffect(() => {
    sendChunk();
  }, [chunkQueue, isSendingChunk, sendChunk]);

  const { handleMicStart, handleMicStop, browserSupportsSpeechRecognition } = useRecording((message) => {
    const parsedMessage = JSON.parse(message);
    setChunkQueue(prevQueue => {
      const newQueue = [...prevQueue, parsedMessage];
      if (newQueue.length >= 10) {
        stopRecording();
      }
      return newQueue;
    });
  });

  const initAudioContext = useCallback(() => {
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);
  }, []);

  useEffect(() => {
    initAudioContext();
  }, [initAudioContext]);


  const handleStop = useCallback(() => {
    

    setIsStopping(true);

    // 오디오 재생 중지
    if (audioContext) {
      
      audioContext.close().then(() => {
        
        setAudioContext(null);
        initAudioContext();
      });
    }

    // 오디오 관련 상태 초기화
    setAudioChunks([]);
    setIsAudioPlaying(false);
    setPlayedChunks([]);

    // 다른 상태 초기화
    setIsResponding(false);
    setIsResponseComplete(true);
    setIsResponseStarted(false);
    setIsTextStreamingComplete(true);
    setIsLoading(false);
    setIsAllAudioPlayed(false);
    setCurrentChunkIndex(0);

    // WebSocket 응답 중지 (필요한 경우)
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      sendMessage({ action: 'stop_response' });
    }

    setTimeout(() => {
      setIsStopping(false);
    }, 1000);
  }, [audioContext, initAudioContext, sendMessage]);



  const handleSubmit = useCallback((e) => {
    if (e) e.preventDefault();
    if (input.trim() === '') return;

    const newMessage = {
      role: 'human',
      message: input,
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    setIsLoading(true);
    setIsResponding(true);
    setIsAudioPlaying(false);
    setIsResponseComplete(false);

    sendMessage({ action: 'message', content: input });
  }, [input, sendMessage]);

  const handleButtonClick = useCallback((e) => {
    e.preventDefault();
    
  
    if (isAudioPlaying || isResponding) {
      
      handleStop();
    } else if (input.trim() !== '') {
      
      handleSubmit(e);
    } 
  }, [isResponding, isAudioPlaying, isResponseComplete, input, handleStop, handleSubmit]);

  const playAudio = useCallback(() => {


    if (!audioContext || audioChunks.length === 0 || isAudioPlaying || isStopping) {

      return;
    }

    setIsAudioPlaying(true);

    const playNextChunk = (index) => {

      if (index >= audioChunks.length) {

        setIsAudioPlaying(false);
        setIsAllAudioPlayed(true);
        return;
      }

      if (playedChunks.includes(index)) {

        playNextChunk(index + 1);
        return;
      }

      const chunk = audioChunks[index];
      const arrayBuffer = base64ToArrayBuffer(chunk);

      audioContext.decodeAudioData(arrayBuffer).then(buffer => {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);

        source.onended = () => {

          setPlayedChunks(prev => [...prev, index]);
          playNextChunk(index + 1);
        };

        source.start(0);
      }).catch(error => {
        console.error('Error decoding audio data:', error);
        playNextChunk(index + 1);
      });
    };

    playNextChunk(playedChunks.length);
  }, [audioContext, audioChunks, isAudioPlaying, isStopping, playedChunks]);

  useEffect(() => {


    if (!isAudioPlaying && audioChunks.length > playedChunks.length && !isResponseTimedOut && !isStopping) {

      playAudio();
    }
  }, [isAudioPlaying, audioChunks, playedChunks, isResponseTimedOut, isStopping, playAudio]);

  const handleAudioChunk = useCallback((data) => {

    const newChunk = data.audio_chunk.data;
    setAudioChunks(prevChunks => [...prevChunks, newChunk]);



    if (!isAudioPlaying && playedChunks.length === audioChunks.length) {

      playAudio();
    }
  }, [isAudioPlaying, playedChunks, audioChunks, playAudio]);

  const handleAudioEnded = useCallback(() => {
    setIsAudioPlaying(false);
    setAudioQueue(prevQueue => {
      if (prevQueue.length > 0) {
        URL.revokeObjectURL(prevQueue[0]);
      }
      return prevQueue.slice(1);
    });
    playAudio();
  }, [playAudio]);



  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.addEventListener('ended', handleAudioEnded);

    return () => {
      audioElement.removeEventListener('ended', handleAudioEnded);
    };
  }, [handleAudioEnded]);

  useEffect(() => {
    if (isTextStreamingComplete && !isAudioPlaying && audioQueue.length > 0) {
      playAudio();
    }
  }, [isTextStreamingComplete, isAudioPlaying, audioQueue, playAudio]);

  const handleResponseStarted = useCallback(() => {
    setIsResponseStarted(true);
    setIsResponding(true);
    setIsResponseComplete(false);
  }, []);

  const handleStreamingMessage = useCallback((data) => {

    const newContent = data.data || data.token || '';
    setMessages(prevMessages => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage && lastMessage.role === 'ai' && lastMessage.isStreaming) {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          message: lastMessage.message + newContent
        };
        return updatedMessages;
      } else {
        return [
          ...prevMessages,
          {
            role: 'ai',
            message: newContent,
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
            isStreaming: true
          }
        ];
      }
    });
  }, []);

  const handleCompleteResponse = useCallback((data) => {
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      if (lastMessage && lastMessage.role === 'ai') {
        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          message: data.response.content,
          isStreaming: false
        };
      }
      return updatedMessages;
    });
    setIsTextStreamingComplete(true);
  }, []);

  const handleTranscription = useCallback((data) => {
    setMessages(prevMessages => [
      ...prevMessages,
      {
        role: 'human',
        message: data.transcription,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      }
    ]);
    setIsLoading(true);
    setIsResponding(true);
    setIsAudioPlaying(false);
    setIsResponseComplete(false);
    setAudioChunks([]);
    setPlayedChunks([]);
  }, []);

  const handleResponseComplete = useCallback(() => {
    setIsLoading(false);
    setIsResponding(false);
    setIsResponseComplete(true);
    setIsResponseStarted(false);
    setIsTextStreamingComplete(true);

    if (isResponseTimedOut) {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          role: 'system',
          message: '응답이 완료되지 않았습니다. 다음 질문을 해주세요.',
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
        }
      ]);
      setIsResponseTimedOut(false);
    }
  }, [isResponseTimedOut]);

  const handleWebSocketMessage = useCallback((event) => {
    if (isStopping) {

      return;
    }

    try {
      const data = JSON.parse(event.data);


      if (data.response_started) {

        handleResponseStarted();

        clearTimeout(responseTimeoutRef.current);
        responseTimeoutRef.current = setTimeout(() => {

          setIsResponseTimedOut(true);
          handleResponseComplete();
        }, 7000);
      }

      if (data.type === 'token' || data.data !== undefined) {
        handleStreamingMessage(data);

        clearTimeout(responseTimeoutRef.current);
        responseTimeoutRef.current = setTimeout(() => {

          setIsResponseTimedOut(true);
          handleResponseComplete();
        }, 7000);
      } else if (data.response) {
        handleCompleteResponse(data);
      } else if (data.transcription) {
        handleTranscription(data);

      }

      if (data.audio_chunk) {
        handleAudioChunk(data);
      }

      if (data.response_stopped) {
        handleStop();
      }

      if (data.type === 'end' || data.response_complete) {
        clearTimeout(responseTimeoutRef.current);
        handleResponseComplete();
        setIsTextStreamingComplete(true);
      }

      setIsWaitingForResponse(false);

    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
      setIsLoading(false);
      setIsResponseComplete(true);
      setIsResponseStarted(false);
      setMessages(prevMessages => [
        ...prevMessages,
        {
          role: 'system',
          message: '메시지 처리 중 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.',
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
        }
      ]);
    }
  }, [handleResponseStarted, handleResponseComplete, isStopping]);

  const connectWebSocket = useCallback(() => {
    if (socketRef.current && (socketRef.current.readyState === WebSocket.OPEN || socketRef.current.readyState === WebSocket.CONNECTING)) {
      return;
    }

    setConnectionStatus('connecting');
    setIsWebSocketConnected(false);
    const socket = new WebSocket('wss://46cidqa9pa.execute-api.ap-northeast-2.amazonaws.com/production/');

    socket.onopen = () => {
      setConnectionStatus('connected');
      setIsWebSocketConnected(true);
      socket.retryCount = 0;
    };

    socket.onmessage = handleWebSocketMessage;

    socket.onclose = (event) => {
      setConnectionStatus('disconnected');
      setIsWebSocketConnected(false);

      if (socket.retryCount < maxRetries) {
        const timeout = Math.min(30000, (1000 * Math.pow(2, socket.retryCount)));
        socket.retryCount++;

        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = setTimeout(() => {
          connectWebSocket();
        }, timeout);
      } else {
        setConnectionStatus('failed');
      }
    };

    socket.onerror = (error) => {
      setConnectionStatus('error');
      setIsWebSocketConnected(false);
    };

    socketRef.current = socket;
  }, [handleWebSocketMessage, maxRetries]);

  useEffect(() => {
    connectWebSocket();
    return () => {
      clearTimeout(reconnectTimeoutRef.current);
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [connectWebSocket]);

  useEffect(() => {
    return () => {
      audioQueue.forEach(url => URL.revokeObjectURL(url));
    };
  }, [audioQueue]);

  const pingServer = useCallback(() => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ action: 'ping' }));
    }
  }, []);

  useEffect(() => {
    const pingInterval = setInterval(pingServer, 1800000); // 30분마다 ping
    return () => clearInterval(pingInterval);
  }, [pingServer]);



  const requestMicPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicPermission('granted');
      return true;
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        setMicPermission('denied');
      } else {
        console.error('Error requesting microphone permission:', err);
      }
      return false;
    }
  };

  const handleMicClick = async () => {
    if (micPermission === 'prompt' || micPermission === 'denied') {
      const permissionGranted = await requestMicPermission();
      if (permissionGranted) {
        startRecording();
      }
    } else if (micPermission === 'granted') {
      if (micOn) {
        stopRecording();
      } else {
        startRecording();
      }
    }
  };

  const startRecording = () => {
    setMicOn(true);
    handleMicStart();
  };

  const stopRecording = () => {
    setMicOn(false);
    handleMicStop();

  };

  useEffect(() => {
    messageRefs.current = messageRefs.current.slice(0, messages.length);
  }, [messages]);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (!isAudioContextStarted && audioContext) {
        audioContext.resume().then(() => {
          setIsAudioContextStarted(true);
        });
      }
    };

    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('keydown', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, [isAudioContextStarted, audioContext]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function ChatMessage({ msg, index, messageRef }) {
    return (
      <div
        key={index}
        className={`chat-message ${msg.role === 'human' ? 'chat-message-human' : 'chat-message-ai'}`}
        ref={messageRef}
      >
        <CSSTransition
          timeout={300}
          classNames={{
            enter: 'message-enter',
            enterActive: 'message-enter-active',
            exit: 'message-exit',
            exitActive: 'message-exit-active',
          }}
        >
          <div className={`message-bubble ${msg.role === 'human' ? 'message-bubble-human' : 'message-bubble-ai'}`}>
            <div className="message-content" dangerouslySetInnerHTML={{ __html: msg.message }}></div>
          </div>
        </CSSTransition>
        <div className={`avatar-container ${msg.role === 'human' ? 'avatar-container-human' : 'avatar-container-ai'}`}>
          <img 
            src={msg.role === 'human' ? humanAvatar : machineAvatar}
            alt={msg.role === 'human' ? 'Human' : 'AI'} 
            className="avatar"
          />
        </div>
        <div className={`timestamp-container ${msg.role === 'human' ? 'timestamp-container-human' : 'timestamp-container-ai'}`}>
          <span className={`timestamp ${msg.role === 'human' ? 'timestamp-human' : 'timestamp-ai'}`}>{msg.timestamp}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container" onClick={() => setIsAudioContextStarted(true)}>
      <button style={{ display: 'none' }} onClick={() => setIsAudioContextStarted(true)}>Start Audio</button>
      <div className="content">
        


        <div className="messages">
          <TransitionGroup>
            {messages.map((msg, index) => {
              if (!messageRefs.current[index]) {
                messageRefs.current[index] = React.createRef();
              }
              return (
                <ChatMessage
                  key={index}
                  msg={msg}
                  index={index}
                  messageRef={messageRefs.current[index]}
                />
              );
            })}
          </TransitionGroup>
          {isLoading && (
            <div className="loading">
              <span role="img" aria-label="loading">⏳</span> 챗봇이 응답을 준비하고 있습니다..
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <form className="input-form" onSubmit={handleFormSubmit}>
          <textarea
            ref={textareaRef}
            name="message"
            placeholder="메세지를 입력하세요."
            value={input}
            onChange={handleChange}
            onPaste={handlePaste}
            required
            className="input-textarea"
            onKeyPress={handleKeyPress}
            disabled={!isWebSocketConnected || isInputDisabled(isResponseComplete, isAudioPlaying)}
            maxLength={MAX_CHARS}
          />
          <div className="button-container">
            <button
              type="submit"
              className="send-button"
              onClick={handleButtonClick}
              disabled={isButtonDisabled(isWebSocketConnected)}
            >
              {getButtonText(isResponding, isAudioPlaying)}
            </button>
            <button
              type="button"
              className={`mic-button ${micOn ? 'mic-on' : ''}`}
              onClick={handleMicClick}
              disabled={!isWebSocketConnected || isMicButtonDisabled(isResponseComplete, isAudioPlaying)}
            >
              {micOn ? '' : ''}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export default _Chatbot;